import { useQuery } from '@tanstack/vue-query';
import { employeesService } from '~/utils/api/employeesService';

export function useAllEmployees() {
  const search = ref('');

  const { data, isLoading } = useQuery({
    queryKey: ['employees'],
    queryFn: async () => {
      const employees = await employeesService.getAllEmployees();

      return {
        ...employees,
        data: employees.data.map((employee) => ({
          ...employee,
          full_name: `${employee.name} ${employee.last_name || ''}`,
          formatedRoles: employee.roles
            ?.map((role) => getRole(role.name))
            .join(', '),
        })),
      };
    },
  });

  const searchedEmployees = computed(() => {
    if (!data.value) return [];

    if (!search.value) return data.value.data;

    return data.value?.data.filter((employee) =>
      employee.full_name.toLowerCase().includes(search.value.toLowerCase()),
    );
  });

  return { data, isLoading, searchedEmployees, search };
}
